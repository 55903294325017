/* eslint-disable no-console */
export default defineNuxtRouteMiddleware((to, _from) => {
  const { appUserMe } = useAppUserStore(usePinia());
  if (!appUserMe) {
    return;
  }
  const { canAccessRoute } = useFeatureFlags();

  if (!canAccessRoute(to)) {
    console.warn("User does not have access to this route");
    return navigateTo("/");
  }
});
