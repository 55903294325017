import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import {
  TopCreativeDigest,
  TopCreativeDigestResponse,
} from "~/types/Notification";
import { Timeframe, TimeoutableToast, Toast } from "~/types/shared";

export const useNotificationStore = defineStore({
  id: "notification-store",
  state: () => {
    return {
      toasts: [],
    } as {
      toasts: Array<TimeoutableToast>;
    };
  },
  actions: {
    notify(notification: Toast) {
      const id = uuidv4();
      const timestamp = new Date();
      timestamp.setMilliseconds(
        timestamp.getMilliseconds() + this.toasts.length,
      );
      this.toasts.push({ ...notification, timestamp, id });
    },

    removeToast(id: string) {
      const index = this.toasts.findIndex((toast) => toast.id === id);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },

    async sendManualAlert(dto: {
      uuid: string;
      message: string;
      metadata: Record<string, any>;
    }) {
      const { error } = await useDatAdsApiFetch(`notification/alert`, {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async getTopCreativeDigestSettings(
      clientId: number,
    ): Promise<TopCreativeDigest | null> {
      const { error, data } = await useDatAdsApiFetch<{
        data: { topCreativeDigest: TopCreativeDigestResponse };
      }>(`notification/top-creative-digest/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        return data.value.data.topCreativeDigest;
      }
      return null;
    },

    async generateAndSendTopCreativeDigestEmailNow(dto: { clientId: number }) {
      const { error } = await useDatAdsApiFetch(
        `notification/top-creative-digest/email/${dto.clientId}/send`,
        {
          method: "POST",
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async generateAndSendTopCreativeDigestSlackNow(dto: { clientId: number }) {
      const { error } = await useDatAdsApiFetch(
        `notification/top-creative-digest/slack/${dto.clientId}/send`,
        {
          method: "POST",
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async updateTopCreativeDigestEmailSettings(dto: {
      clientId: number;
      cronSchedule: string;
      numCreatives: number;
      additionalEmails: string[];
      senderEmail: string;
      senderName: string;
      lookback: Timeframe | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `notification/top-creative-digest/email`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async updateTopCreativeDigestSlackSettings(dto: {
      clientId: number;
      cronSchedule: string;
      numCreatives: number;
      slackChannel: string | null;
      enabled: boolean;
      lookback: Timeframe | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `notification/top-creative-digest/slack`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },

    async optOutTopCreativeDigest(dto: { clientId: number; optOut: boolean }) {
      const { error } = await useDatAdsApiFetch(
        `notification/top-creative-digest/email/opt-out`,
        {
          method: "POST",
          body: dto,
        },
      );
      if (error.value) {
        const errorMaybe = useErrorHandler(error.value);
        return errorMaybe;
      }
      return null;
    },
  },
  getters: {},
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationStore, import.meta.hot),
  );
}
