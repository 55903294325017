export default defineNuxtRouteMiddleware((to, _from) => {
  const { appUserMe } = useAppUserStore(usePinia());
  const { getActiveWorkspace } = useWorkspace();
  const activeWorkspace = getActiveWorkspace(appUserMe);
  if (!activeWorkspace) {
    return;
  }
  if (!activeWorkspace.completedOnboarding) {
    if (to.path.includes("/onboarding")) {
      return;
    }
    return navigateTo("/onboarding/user-details");
  }
});
