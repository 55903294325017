import { AdGroupAdStatus } from "~/types/Creative";
import {
  GoogleMetric,
  MetaMetric,
  MiscMetric,
  ScoreMetric,
  TiktokMetric,
} from "~/types/Metrics";
import { Emotion } from "~/types/Nlp";
import {
  AdFormat,
  AdProvider,
  AttributionWindow,
  CampaignObjective,
  Config,
  ConfigWithDescriptionMaybe,
  ConfigWithGroup,
  ConfigWithGroupAndDescriptionMaybe,
  ConfigWithGroupAndLogoAndDescription,
  EgressProvider,
  GroupBy,
  Provider,
  QueryOption,
  SortBy,
  Timeframe,
} from "~/types/shared";

export const SORT_BY: Array<ConfigWithDescriptionMaybe<SortBy>> = [
  {
    id: SortBy.CUSTOM_ORDER,
    displayName: "Custom Order",
    enabled: true,
    description:
      "Order by your own custom order instead of a performance metric",
    featureFlag: "creative-comparison-custom-order_2024-08-14",
  },
  {
    id: SortBy.AD_CREATION_DATE,
    displayName: "Ad Creation Date",
    enabled: true,
  },
];

export const ATTRIBUTION_WINDOWS: Array<
  ConfigWithDescriptionMaybe<AttributionWindow>
> = [
  {
    id: AttributionWindow.DEFAULT,
    displayName: "Default (Defined by Ad Set)",
    enabled: true,
    description: "Defined by adset",
  },
  {
    id: AttributionWindow.SEVEN_DAY_CLICK_OR_ONE_DAY_VIEW,
    displayName: "7D-Click or 1D-View",
    enabled: true,
  },
  {
    id: AttributionWindow.ONE_DAY_CLICK,
    displayName: "1D-Click",
    enabled: true,
  },
  {
    id: AttributionWindow.ONE_DAY_VIEW,
    displayName: "1D-View",
    enabled: true,
  },
  {
    id: AttributionWindow.ONE_DAY_EV,
    displayName: "1D-Engaged View",
    enabled: true,
  },
  {
    id: AttributionWindow.SEVEN_DAY_CLICK,
    displayName: "7D-Click",
    enabled: true,
  },
  {
    id: AttributionWindow.SEVEN_DAY_VIEW,
    displayName: "7D-View",
    enabled: true,
  },
  {
    id: AttributionWindow.TWENTY_EIGHT_DAY_CLICK,
    displayName: "28D-Click",
    enabled: true,
  },
  {
    id: AttributionWindow.TWENTY_EIGHT_DAY_VIEW,
    displayName: "28D-View",
    enabled: true,
  },
];

export const GROUP_BY: Array<Config<GroupBy>> = [
  {
    id: GroupBy.NONE,
    displayName: "No Grouping",
    enabled: true,
  },
  {
    id: GroupBy.CREATIVE_ID,
    displayName: "Creative",
    enabled: true,
  },
  {
    id: GroupBy.ADNAME,
    displayName: "Ad Name",
    enabled: true,
  },
  {
    id: GroupBy.AD_GROUP_ID,
    displayName: "Ad Group",
    enabled: true,
  },
  {
    id: GroupBy.AD_CAMPAIGN_ID,
    displayName: "Campaign",
    enabled: true,
  },
  {
    id: GroupBy.AD_FORMAT,
    displayName: "Ad Format",
    enabled: true,
  },
  {
    id: GroupBy.LANDING_PAGE,
    displayName: "Landing Page",
    enabled: true,
  },
  {
    id: GroupBy.HEADLINE,
    displayName: "Headline",
    enabled: true,
  },
  {
    id: GroupBy.DESCRIPTION,
    displayName: "Description",
    enabled: true,
  },
  {
    id: GroupBy.POST_ID,
    displayName: "Post ID",
    enabled: true,
  },
  {
    id: GroupBy.PRIMARY_EMOTION,
    displayName: "Primary Emotion",
    enabled: true,
    featureFlag: "emotion-classification_2025-03-07",
  },
];

export const TIMEFRAMES: Array<Config<Timeframe>> = [
  {
    id: Timeframe.previous_period,
    displayName: "Previous period",
    enabled: true,
  },
  {
    id: Timeframe.today,
    displayName: "Today",
    enabled: true,
  },
  {
    id: Timeframe.yesterday,
    displayName: "Yesterday",
    enabled: true,
  },
  {
    id: Timeframe.three_days,
    displayName: "Last 3 days",
    enabled: true,
  },
  {
    id: Timeframe.seven_days,
    displayName: "Last 7 days",
    enabled: true,
  },
  // 05.03.2023: Rename from Last 2 weeks -> Last 14 days
  {
    id: Timeframe.two_weeks,
    displayName: "Last 14 days",
    enabled: true,
  },
  {
    id: Timeframe.thirty_days,
    displayName: "Last 30 days",
    enabled: true,
  },
  {
    id: Timeframe.ninety_days,
    displayName: "Last 90 days",
    enabled: true,
  },
  {
    id: Timeframe.one_hundred_eighty_days,
    displayName: "Last 180 days",
    enabled: true,
  },
  {
    id: Timeframe.this_one_week,
    displayName: "This week",
    enabled: true,
  },
  {
    id: Timeframe.one_week,
    displayName: "Last week",
    enabled: true,
  },
  // Legacy
  {
    id: Timeframe.four_weeks,
    displayName: "Last 4 weeks",
    enabled: false,
  },
  {
    id: Timeframe.this_one_month,
    displayName: "This month",
    enabled: true,
  },
  {
    id: Timeframe.one_month,
    displayName: "Last month",
    enabled: true,
  },
  {
    id: Timeframe.three_months,
    displayName: "Last 3 months",
    enabled: true,
  },
  {
    id: Timeframe.six_months,
    displayName: "Last 6 months",
    enabled: true,
  },
  {
    id: Timeframe.twelve_months,
    displayName: "Last 12 months",
    enabled: true,
  },
  {
    id: Timeframe.all_time,
    displayName: "All time",
    enabled: false,
  },
];

export const CONNECTIONS: Array<
  ConfigWithGroupAndLogoAndDescription<Provider | EgressProvider>
> = [
  {
    id: Provider.META,
    displayName: "Meta",
    logo: "/img/meta.png",
    enabled: true,
    group: "Ads",
    description: "Online advertising platform from Meta",
  },
  {
    id: Provider.TIKTOK,
    displayName: "TikTok",
    logo: "/img/tiktok.png",
    enabled: true,
    group: "Ads",
    description: "Online advertising platform from TikTok",
  },
  {
    id: Provider.GOOGLE,
    displayName: "Google",
    logo: "/img/google.png",
    enabled: true,
    group: "Ads",
    description: "Online advertising platform from Google",
    featureFlag: "google-ads_2024-03-13",
  },
  {
    id: Provider.ADTRIBUTE,
    displayName: "adtribute",
    logo: "/img/adtribute.jpeg",
    enabled: true,
    group: "Attribution",
    description: "3rd Party Attribution Platform for better insights",
    featureFlag: "adtribute_2024-05-23",
  },
  {
    id: Provider.HYROS,
    displayName: "Hyros",
    logo: "/img/hyros.png",
    enabled: true,
    group: "Attribution",
    description: "3rd Party Attribution Platform for better insights",
    featureFlag: "hyros_2024-11-02",
  },
  {
    id: Provider.TRIPLEWHALE,
    displayName: "TripleWhale",
    logo: "/img/triplewhale.png",
    enabled: true,
    group: "Attribution",
    description: "3rd Party Attribution Platform for better insights",
    featureFlag: "triplewhale_2024-11-26",
  },
  {
    id: Provider.TRACIFY,
    displayName: "Tracify",
    logo: "/img/tracify.png",
    enabled: true,
    group: "Attribution",
    description: "3rd Party Attribution Platform for better insights",
    featureFlag: "tracify_2024-11-27",
  },
  {
    id: Provider.GOOGLE_SHEETS,
    displayName: "Google Sheets",
    logo: "/img/google-sheets.png",
    enabled: true,
    group: "Attribution",
    description: "Add custom data to DatAds",
    featureFlag: "google-sheets_2024-08-16",
  },
  {
    id: EgressProvider.SLACK,
    displayName: "Slack",
    logo: "/img/slack.png",
    enabled: true,
    group: "Notifications",
    description: "Get notifications from DatAds directly into Slack",
    featureFlag: "slack_2024-11-29",
  },
];

export const MONTHS: Array<Config<number>> = [
  {
    id: 1,
    displayName: "January",
    enabled: true,
  },
  {
    id: 2,
    displayName: "February",
    enabled: true,
  },
  {
    id: 3,
    displayName: "March",
    enabled: true,
  },
  {
    id: 4,
    displayName: "April",
    enabled: true,
  },
  {
    id: 5,
    displayName: "May",
    enabled: true,
  },
  {
    id: 6,
    displayName: "June",
    enabled: true,
  },
  {
    id: 7,
    displayName: "July",
    enabled: true,
  },
  {
    id: 8,
    displayName: "August",
    enabled: true,
  },
  {
    id: 9,
    displayName: "September",
    enabled: true,
  },
  {
    id: 10,
    displayName: "October",
    enabled: true,
  },
  {
    id: 11,
    displayName: "November",
    enabled: true,
  },
  {
    id: 12,
    displayName: "December",
    enabled: true,
  },
];

export const AD_METRICS_FILTER_BY_PROPERTIES: Array<
  ConfigWithGroup<string> & {
    queryOptions: Array<string>;
    component: "form-input" | "form-select" | "select-tag";
    filterValueOptions: Array<{
      value: any;
      displayValue: string;
      color: string;
      providers: Provider[];
    }>;
  }
> = [
  {
    id: "primaryEmotion",
    displayName: "Primary Emotion",
    enabled: true,
    queryOptions: ["eq", "neq"],
    component: "form-select",
    featureFlag: "emotion-classification_2025-03-07",
    group: "AI Analysis",
    filterValueOptions: [
      {
        value: Emotion.joy,
        displayValue: "Joy",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: Emotion.sadness,
        displayValue: "Sadness",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: Emotion.anger,
        displayValue: "Anger",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: Emotion.fear,
        displayValue: "Fear",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: Emotion.surprise,
        displayValue: "Surprise",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: Emotion.disgust,
        displayValue: "Disgust",
        color: "",
        providers: [...AdProvider],
      },
    ],
  },
  {
    id: "adAccountId",
    displayName: "Ad Account ID",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adCampaignId",
    displayName: "Campaign ID",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adGroupId",
    displayName: "Ad Group ID",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adGroupAdId",
    displayName: "Ad ID",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adAccountName",
    displayName: "Ad Account Name",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adCampaignName",
    displayName: "Campaign Name",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adGroupName",
    displayName: "Ad Group Name",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adGroupAdName",
    displayName: "Ad Name",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "headline",
    displayName: "Headline",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "description",
    displayName: "Description",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    filterValueOptions: [],
    group: "Metadata",
  },
  {
    id: "adGroupAdStatus",
    displayName: "Ad Status",
    enabled: true,
    queryOptions: ["eq", "neq"],
    component: "form-select",
    group: "Metadata",
    filterValueOptions: [
      {
        value: AdGroupAdStatus.ACTIVE,
        displayValue: "Active",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: AdGroupAdStatus.INACTIVE,
        displayValue: "Inactive",
        color: "",
        providers: [...AdProvider],
      },
    ],
  },
  {
    id: "landingPage",
    displayName: "Landing Page",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    group: "Metadata",
    filterValueOptions: [],
  },
  {
    id: "adFormat",
    displayName: "Ad Format",
    enabled: true,
    queryOptions: ["eq", "neq"],
    component: "form-select",
    group: "Metadata",
    filterValueOptions: [
      {
        value: AdFormat.IMAGE,
        displayValue: "Image",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: AdFormat.VIDEO,
        displayValue: "Video",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: AdFormat.CAROUSEL,
        displayValue: "Carousel",
        color: "",
        providers: [...AdProvider],
      },
      {
        value: AdFormat.MIXED,
        displayValue: "Mixed",
        color: "",
        providers: [...AdProvider],
      },
    ],
  },
  {
    id: "postId",
    displayName: "Post ID",
    enabled: true,
    queryOptions: ["eq", "neq", "contains", "containsnot"],
    component: "form-input",
    group: "Metadata",
    filterValueOptions: [],
  },
  {
    id: "creativeId",
    displayName: "Creative ID",
    enabled: false,
    queryOptions: ["eq", "neq"],
    component: "form-input",
    group: "Metadata",
    filterValueOptions: [],
  },
  {
    id: "adGroupAdTag",
    displayName: "Tag",
    enabled: true,
    queryOptions: ["eq", "neq"],
    component: "select-tag",
    group: "Metadata",
    filterValueOptions: [],
  },
  {
    id: "adCampaignObjective",
    displayName: "Campaign Objective",
    enabled: true,
    queryOptions: ["eq", "neq"],
    component: "form-select",
    group: "Metadata",
    filterValueOptions: [
      // Meta
      {
        value: CampaignObjective.OUTCOME_AWARENESS,
        displayValue: "Awareness",
        color: "",
        providers: [Provider.META],
      },
      {
        value: CampaignObjective.OUTCOME_TRAFFIC,
        displayValue: "Traffic",
        color: "",
        providers: [Provider.META],
      },
      {
        value: CampaignObjective.OUTCOME_ENGAGEMENT,
        displayValue: "Engagement",
        color: "",
        providers: [Provider.META],
      },
      {
        value: CampaignObjective.OUTCOME_APP_PROMOTION,
        displayValue: "App promotion",
        color: "",
        providers: [Provider.META],
      },
      {
        value: CampaignObjective.OUTCOME_LEADS,
        displayValue: "Leads",
        color: "",
        providers: [Provider.META],
      },
      {
        value: CampaignObjective.OUTCOME_SALES,
        displayValue: "Sales",
        color: "",
        providers: [Provider.META],
      },
      // TikTok
      {
        value: CampaignObjective.REACH,
        displayValue: "Reach",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.TRAFFIC,
        displayValue: "Traffic",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.VIDEO_VIEWS,
        displayValue: "Video Views",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.ENGAGEMENT,
        displayValue: "Community Interaction",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.APP_INSTALL,
        displayValue: "App Promotion",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.LEAD_GENERATION,
        displayValue: "Lead Generation",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.CONVERSIONS,
        displayValue: "Website Conversions",
        color: "",
        providers: [Provider.TIKTOK],
      },
      {
        value: CampaignObjective.SHOP_PURCHASES,
        displayValue: "Product Sales",
        color: "",
        providers: [Provider.TIKTOK],
      },
    ],
  },
];

export const QUERY_OPTIONS: Array<Config<QueryOption>> = [
  {
    id: "eq",
    displayName: "equals",
    enabled: true,
  },
  {
    id: "neq",
    displayName: "not equals",
    enabled: true,
  },
  {
    id: "gt",
    displayName: "greater than",
    enabled: true,
  },
  {
    id: "lt",
    displayName: "less than",
    enabled: true,
  },
  {
    id: "gte",
    displayName: "greater than or equal",
    enabled: true,
  },
  {
    id: "lte",
    displayName: "less than or equal",
    enabled: true,
  },
  {
    id: "contains",
    displayName: "contains",
    enabled: true,
  },
  {
    id: "containsnot",
    displayName: "contains not",
    enabled: true,
  },
];

export const AD_FORMATS: Array<Config<AdFormat>> = [
  {
    id: AdFormat.VIDEO,
    displayName: "Video",
    enabled: true,
  },
  {
    id: AdFormat.IMAGE,
    displayName: "Image",
    enabled: true,
  },
  {
    id: AdFormat.CAROUSEL,
    displayName: "Carousel",
    enabled: true,
  },
  {
    id: AdFormat.MIXED,
    displayName: "Mixed",
    enabled: true,
  },
];

export const AD_STATUSES: Array<Config<AdGroupAdStatus>> = [
  {
    id: AdGroupAdStatus.ACTIVE,
    displayName: "Active",
    enabled: true,
  },
  {
    id: AdGroupAdStatus.INACTIVE,
    displayName: "Inactive",
    enabled: true,
  },
];

export const SCORE_METRICS: Array<ConfigWithDescriptionMaybe<ScoreMetric>> = [
  {
    id: ScoreMetric.HookScore,
    displayName: "Hook Score",
    description: "Measures ability to grab attention",
    enabled: true,
    featureFlag: "metric-scores_2024-10-29",
  },
  {
    id: ScoreMetric.WatchScore,
    displayName: "Watch Score",
    description: "Measures ability keep a viewer engaged in the ad content",
    enabled: true,
    featureFlag: "metric-scores_2024-10-29",
  },
  {
    id: ScoreMetric.ClickScore,
    displayName: "Click Score",
    description: "Measures ability to drive viewers to your landing page",
    enabled: true,
    featureFlag: "metric-scores_2024-10-29",
  },
  {
    id: ScoreMetric.ConversionScore,
    displayName: "Conversion Score",
    description: "Measures ability to to drive conversions",
    enabled: true,
    featureFlag: "metric-scores_2024-10-29",
  },
];

export const MISC_METRICS: Array<ConfigWithDescriptionMaybe<MiscMetric>> = [
  {
    id: MiscMetric.ad_count,
    displayName: "Ad Count",
    description: "Number of ads in group",
    enabled: true,
    featureFlag: "misc-metrics_2024-12-06",
  },
  {
    id: MiscMetric.revenue_per_ad,
    displayName: "Revenue per Ad",
    description: "Revenue / Ad Count",
    enabled: true,
    featureFlag: "misc-metrics_2024-12-06",
  },
];

export const META_METRICS: Array<
  ConfigWithGroupAndDescriptionMaybe<MetaMetric>
> = [
  {
    id: MetaMetric.spend,
    displayName: "Spend",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.reach,
    displayName: "Reach",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.impressions,
    displayName: "Impressions",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.frequency,
    displayName: "Frequency",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.cpm,
    displayName: "CPM",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.clicks,
    displayName: "Clicks",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.cpc,
    displayName: "CPC",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.ctr,
    displayName: "CTR (Link Click Rate)",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.ctr_all,
    displayName: "CTR (All)",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.outbound_clicks,
    displayName: "Outbound Clicks",
    enabled: true,
    group: "Performance",
    featureFlag: "metrics-outbound-clicks_2024-07-03",
  },
  {
    id: MetaMetric.outbound_clicks_ctr,
    displayName: "Outbound Clicks CTR",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.outbound_clicks_cpc,
    displayName: "Outbound Clicks CPC",
    enabled: true,
    group: "Performance",
  },
  {
    id: MetaMetric.unique_outbound_clicks,
    displayName: "Unique Outbound Clicks",
    enabled: true,
    group: "Performance",
    featureFlag: "metrics-outbound-clicks_2024-07-03",
  },
  {
    id: MetaMetric.unique_outbound_clicks_ctr,
    displayName: "Unique Outbound CTR",
    enabled: true,
    group: "Performance",
    featureFlag: "metrics-outbound-clicks_2024-07-03",
  },
  {
    id: MetaMetric.unique_outbound_clicks_cpc,
    displayName: "Unique Outbound CPC",
    enabled: true,
    group: "Performance",
    featureFlag: "metrics-outbound-clicks_2024-07-03",
  },
  {
    id: MetaMetric.purchases,
    displayName: "Purchases",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_purchase,
    displayName: "Cost per Purchase",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.purchase_roas,
    displayName: "Purchase ROAS",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.purchase_conversion_value,
    displayName: "Purchase Conversion Value",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.view_content,
    displayName: "View Content",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_view_content,
    displayName: "Cost per View Content",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.add_to_cart,
    displayName: "Add to Cart",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_add_to_cart,
    displayName: "Cost per Add to Cart",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.initiate_checkout,
    displayName: "Initiate Checkout",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_initiate_checkout,
    displayName: "Cost per Initate Checkout",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.add_payment_info,
    displayName: "Add Payment Info",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_add_payment_info,
    displayName: "Cost per Add Payment Info",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.post_comment,
    displayName: "Post Comments",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_post_comment,
    displayName: "Cost per Post Comments",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.post_save,
    displayName: "Post Saves",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_post_save,
    displayName: "Cost per Post Saves",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.post_share,
    displayName: "Post Shares",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_post_share,
    displayName: "Cost per Post Shares",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.post_reaction,
    displayName: "Post Reactions",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_post_reaction,
    displayName: "Cost per Post Reactions",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.post_engagement,
    displayName: "Post Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_post_engagement,
    displayName: "Cost per Post Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.page_engagement,
    displayName: "Page Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_page_engagement,
    displayName: "Cost per Page Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.page_like,
    displayName: "Likes",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_page_like,
    displayName: "Cost per Likes",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.page_photo_view,
    displayName: "Photo Views",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.cost_per_page_photo_view,
    displayName: "Cost per Photo Views",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: MetaMetric.video_plays,
    displayName: "Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_plays_2sec,
    displayName: "2-Second Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_plays_3sec,
    displayName: "3-Second Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_avg_play_time,
    displayName: "Average Watch Time per Video View",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_views_p25,
    displayName: "Video Views at 25%",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_views_p50,
    displayName: "Video Views at 50%",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_views_p75,
    displayName: "Video Views at 75%",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_views_p100,
    displayName: "Video Views at 100%",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_view_rate_p100,
    displayName: "Video Views at 100% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_view_rate_p75,
    displayName: "Video Views at 75% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_view_rate_p50,
    displayName: "Video Views at 50% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_view_rate_p25,
    displayName: "Video Views at 25% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.video_view_rate,
    displayName: "Video View Rate",
    enabled: true,
    group: "Media",
    description: "Video Views / Impressions",
  },
  {
    id: MetaMetric.thumb_stop_ratio,
    displayName: "Thumbstop Ratio",
    enabled: true,
    group: "Media",
    description: "3-Second Video Views / Impressions",
  },
  {
    id: MetaMetric.datads_thumb_stop_ratio,
    displayName: "DatAds Thumbstop Ratio",
    enabled: true,
    group: "Media",
    description: "2-Second Video Views / Impressions",
  },
  {
    id: MetaMetric.clicks_per_video_play,
    displayName: "Clicks / Video Plays",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.hook_to_click_ratio,
    displayName: "Hook to Click Ratio",
    enabled: true,
    group: "Media",
    description: "Clicks / 3-Seconds Video Views",
  },
  {
    id: MetaMetric.stop_scroll_ratio,
    displayName: "Stop Scroll Ratio",
    enabled: true,
    group: "Media",
    description: "Video Plays / Impressions",
  },
  {
    id: MetaMetric.hold_rate,
    displayName: "Hold Rate",
    enabled: true,
    group: "Media",
    description: "Thruplays / Impressions",
  },
  {
    id: MetaMetric.thruplays,
    displayName: "Thru Plays",
    enabled: true,
    group: "Media",
  },
  {
    id: MetaMetric.view_content_per_link_click,
    displayName: "View Content / Link Click",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.add_to_cart_per_view_content,
    displayName: "Add to Cart / View Content",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.initiate_checkout_per_add_to_cart,
    displayName: "Initiate Checkout / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.add_payment_info_per_add_to_cart,
    displayName: "Add Payment Info / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.purchase_per_add_to_cart,
    displayName: "Purchase / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.app_install,
    displayName: "App Install",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_app_install,
    displayName: "Cost per App Install",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.complete_registration,
    displayName: "Complete Registration",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_complete_registration,
    displayName: "Cost per Complete Registration",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.contact,
    displayName: "Contact",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_contact,
    displayName: "Cost per Contact",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.customize_product,
    displayName: "Customize Product",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_customize_product,
    displayName: "Cost per Customize Product",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.donate,
    displayName: "Donate",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_donate,
    displayName: "Cost per Donate",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.find_location,
    displayName: "Find Location",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_find_location,
    displayName: "Cost per Find Location",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.lead,
    displayName: "Lead",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_lead,
    displayName: "Cost per Lead",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.schedule,
    displayName: "Schedule",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_schedule,
    displayName: "Cost per Schedule",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.search,
    displayName: "Search",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_search,
    displayName: "Cost per Search",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.start_trial,
    displayName: "Start Trial",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_start_trial,
    displayName: "Cost per Start Trial",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.submit_application,
    displayName: "Submit Application",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_submit_application,
    displayName: "Cost per Submit Application",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.subscribe,
    displayName: "Subscribe",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_subscribe,
    displayName: "Cost per Subscribe",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.add_to_wishlist,
    displayName: "Add to Wishlist",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_add_to_wishlist,
    displayName: "Cost per Add to Wishlist",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.landing_page_view,
    displayName: "Landing Page Views",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.cost_per_landing_page_view,
    displayName: "Cost per Landing Page Views",
    enabled: true,
    group: "Conversions",
  },
  {
    id: MetaMetric.conversion_rate,
    displayName: "Conversion Rate",
    enabled: true,
    group: "DatAds Ratios",
    description: "Purchases / Landing Page Views",
  },
  {
    id: MetaMetric.purchase_per_clicks,
    displayName: "Purchase / Clicks",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.add_to_cart_per_clicks,
    displayName: "Add to Cart / Clicks",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: MetaMetric.aov,
    displayName: "Average Order Value (AOV)",
    enabled: true,
    group: "DatAds Ratios",
    description: "Purchase Conversion Value / Purchases",
  },
];

export const TIKTOK_METRICS: Array<
  ConfigWithGroupAndDescriptionMaybe<TiktokMetric>
> = [
  {
    id: TiktokMetric.spend,
    displayName: "Spend",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.reach,
    displayName: "Reach",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.impressions,
    displayName: "Impressions",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.frequency,
    displayName: "Frequency",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.cpm,
    displayName: "CPM",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.clicks,
    displayName: "Clicks",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.cpc,
    displayName: "CPC",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.ctr,
    displayName: "CTR (Destination)",
    enabled: true,
    group: "Performance",
  },
  {
    id: TiktokMetric.complete_payment,
    displayName: "Complete Payments",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_purchase,
    displayName: "SKAN Purchases",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.purchase,
    displayName: "Purchases",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_complete_payment,
    displayName: "Cost per Complete Payment",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.complete_payment_roas,
    displayName: "Complete Payment ROAS",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.total_complete_payment_rate,
    displayName: "Total Complete Payment Value",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.product_details_page_browse,
    displayName: "View Content (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.view_content,
    displayName: "View Content (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_view_content,
    displayName: "SKAN View Content",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_product_details_page_browse,
    displayName: "Cost per View Content (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_view_content,
    displayName: "Cost per View Content (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_cost_per_view_content,
    displayName: "SKAN Cost per View Content",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.app_event_add_to_cart,
    displayName: "Add to Cart (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.web_event_add_to_cart,
    displayName: "Add to Cart (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_add_to_cart,
    displayName: "SKAN Add to Cart",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_app_event_add_to_cart,
    displayName: "Cost per Add to Cart (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_web_event_add_to_cart,
    displayName: "Cost per Add to Cart (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_cost_per_add_to_cart,
    displayName: "SKAN Cost per Add to Cart",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.initiate_checkout,
    displayName: "Initiate Checkout (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.checkout,
    displayName: "Initiate Checkout (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_checkout,
    displayName: "SKAN Initiate Checkout",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_initiate_checkout,
    displayName: "Cost per Initiate Checkout (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_checkout,
    displayName: "Cost per Initiate Checkout (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_cost_per_checkout,
    displayName: "SKAN Cost per Initiate Checkout",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.add_billing,
    displayName: "Add Payment Info (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.add_payment_info,
    displayName: "Add Payment Info (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_add_payment_info,
    displayName: "SKAN Add Payment Info",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_add_billing,
    displayName: "Cost per Add Payment Info (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_add_payment_info,
    displayName: "Cost per Add Payment Info (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_cost_per_add_payment_info,
    displayName: "SKAN Cost per Add Payment Info",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.video_play_actions,
    displayName: "Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.engagements,
    displayName: "Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_engagements,
    displayName: "Cost per Engagements",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.follows,
    displayName: "Paid Follows",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_follows,
    displayName: "Cost per Paid Follows",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.likes,
    displayName: "Paid Likes",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_likes,
    displayName: "Cost per Paid Likes",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.comments,
    displayName: "Paid Comments",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_comments,
    displayName: "Cost per Paid Comments",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.shares,
    displayName: "Paid Shares",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_shares,
    displayName: "Cost per Paid Shares",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.profile_visits,
    displayName: "Paid Profile Visits",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.cost_per_profile_visits,
    displayName: "Cost per Paid Profile Visits",
    enabled: true,
    group: "Social",
    featureFlag: "metrics-engagement_2024-10-23",
  },
  {
    id: TiktokMetric.video_watched_2s,
    displayName: "2-Second Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_watched_6s,
    displayName: "6-Second Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.average_video_play,
    displayName: "Average Watch Time per Video View",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.average_video_play_per_user,
    displayName: "Average Watch Time per Person",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_views_p25,
    displayName: "Video Views at 25%",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_views_p50,
    displayName: "Video Views at 50%",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_views_p75,
    displayName: "Video Views at 75%",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_views_p100,
    displayName: "Video Views at 100%",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_view_rate_p100,
    displayName: "Video Views at 100% (in %)",
    enabled: true,
    group: "Media",
    description: "Video Views / Impressions",
  },
  {
    id: TiktokMetric.video_view_rate_p75,
    displayName: "Video Views at 75% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_view_rate_p50,
    displayName: "Video Views at 50% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_view_rate_p25,
    displayName: "Video Views at 25% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.video_view_rate,
    displayName: "Video View Rate",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.datads_thumb_stop_ratio,
    displayName: "DatAds Thumbstop Ratio",
    enabled: true,
    group: "Media",
    description: "2-Second Video Views / Impressions",
  },
  {
    id: TiktokMetric.clicks_per_video_play,
    displayName: "Clicks / Video Plays",
    enabled: true,
    group: "Media",
  },
  {
    id: TiktokMetric.hook_to_click_ratio,
    displayName: "Hook to Click Ratio",
    enabled: true,
    group: "Media",
    description: "Clicks / 2-Second Video Views",
  },
  {
    id: TiktokMetric.stop_scroll_ratio,
    displayName: "Stop Scroll Ratio",
    enabled: true,
    group: "Media",
    description: "Video Plays / Impressions",
  },
  {
    id: TiktokMetric.hold_rate,
    displayName: "DatAds Hold Rate",
    enabled: true,
    group: "Media",
    description: "Video Views at 50% / 2-Second Video Views",
  },
  {
    id: TiktokMetric.view_content_per_link_click,
    displayName: "View Content / Link Click",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.add_to_cart_per_view_content,
    displayName: "Add to Cart / View Content",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.initiate_checkout_per_add_to_cart,
    displayName: "Initiate Checkout / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.add_payment_info_per_add_to_cart,
    displayName: "Add Payment Info / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.purchase_per_add_to_cart,
    displayName: "Purchase / Add to Cart",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.add_to_wishlist,
    displayName: "Add to Wishlist (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_add_to_wishlist,
    displayName: "Cost per Add to Wishlist (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.on_web_add_to_wishlist,
    displayName: "Add to Wishlist (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_on_web_add_to_wishlist,
    displayName: "Cost per Add to Wishlist (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_add_to_wishlist,
    displayName: "SKAN Add to Wishlist",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_skan_add_to_wishlist,
    displayName: "SKAN Cost per Add to Wishlist",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.button_click,
    displayName: "Button Click",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_button_click,
    displayName: "Cost per Button Click",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.registration,
    displayName: "Registration (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_registration,
    displayName: "Cost per Registration (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.user_registration,
    displayName: "Registration (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_user_registration,
    displayName: "Cost per Registration (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_registration,
    displayName: "SKAN Registration",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_skan_registration,
    displayName: "SKAN Cost per Registration",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.online_consult,
    displayName: "Contact",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_online_consult,
    displayName: "Cost per Contact",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.download_start,
    displayName: "Download",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_download_start,
    displayName: "Cost per Download",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.on_web_order,
    displayName: "Place an Order",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_on_web_order,
    displayName: "Cost per Place an Order",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.search,
    displayName: "Search (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_search,
    displayName: "Cost per Search (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.page_event_search,
    displayName: "Search (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_page_event_search,
    displayName: "Cost per Search (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_search,
    displayName: "SKAN Search",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_skan_search,
    displayName: "SKAN Cost per Search",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.form,
    displayName: "Submit Form",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_form,
    displayName: "Cost per Submit Form",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.onsite_form,
    displayName: "Submit Form (Onsite)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_onsite_form,
    displayName: "Cost per Submit Form (Onsite)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.subscribe,
    displayName: "Subscribe (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_subscribe,
    displayName: "Cost per Subscribe (App Event)",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.on_web_subscribe,
    displayName: "Subscribe (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_on_web_subscribe,
    displayName: "Cost per Subscribe (Page Event)",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.skan_subscribe,
    displayName: "SKAN Subscribe",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_skan_subscribe,
    displayName: "SKAN Cost per Subscribe",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.app_install,
    displayName: "App Install",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.cost_per_app_install,
    displayName: "Cost per App Install",
    enabled: true,
    group: "App Conversions",
  },
  {
    id: TiktokMetric.skan_app_install,
    displayName: "SKAN App Install",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.cost_per_skan_app_install,
    displayName: "SKAN Cost per App Install",
    enabled: true,
    group: "SKAN Conversions",
  },
  {
    id: TiktokMetric.total_landing_page_view,
    displayName: "Landing Page Views",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.cost_per_total_landing_page_view,
    displayName: "Cost per Landing Page Views",
    enabled: true,
    group: "Conversions",
  },
  {
    id: TiktokMetric.conversion_rate,
    displayName: "Conversion Rate",
    enabled: true,
    group: "DatAds Ratios",
    description: "Complete Payments / Landing Page Views",
  },
  {
    id: TiktokMetric.purchase_per_clicks,
    displayName: "Complete Payments / Clicks",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.add_to_cart_per_clicks,
    displayName: "Add to Cart / Clicks",
    enabled: true,
    group: "DatAds Ratios",
  },
  {
    id: TiktokMetric.aov,
    displayName: "Average Order Value (AOV)",
    enabled: true,
    group: "DatAds Ratios",
    description: "Purchase Conversion Value / Purchases",
  },
];

export const GOOGLE_METRICS: Array<
  ConfigWithGroupAndDescriptionMaybe<GoogleMetric>
> = [
  {
    id: GoogleMetric.total_cost,
    displayName: "Total Cost",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.conversions,
    displayName: "Conversions",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.conversions_value,
    displayName: "Conversions Value",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.all_conversions,
    displayName: "All Conversions",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.all_conversions_value,
    displayName: "All Conversions Value",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.average_cart_size,
    displayName: "AOV",
    enabled: true,
    group: "Conversions",
    description: "Conversions Value / Orders",
  },
  {
    id: GoogleMetric.roas,
    displayName: "ROAS",
    enabled: true,
    group: "Conversions",
    description: "Conversions Value / Total Cost",
  },
  {
    id: GoogleMetric.average_cpc,
    displayName: "Average CPC",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.average_cpm,
    displayName: "Average CPM",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.average_cpv,
    displayName: "Average CPV",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.average_cpe,
    displayName: "Average CPE",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.clicks,
    displayName: "Clicks",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.ctr,
    displayName: "CTR",
    enabled: true,
    group: "Performance",
    description: "Clicks / Impressions",
  },
  {
    id: GoogleMetric.engagements,
    displayName: "Engagements",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.engagement_rate,
    displayName: "Engagement Rate",
    enabled: true,
    group: "Performance",
    description: "Engagements / Impressions",
  },
  {
    id: GoogleMetric.impressions,
    displayName: "Impressions",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.interactions,
    displayName: "Interactions",
    enabled: true,
    group: "Performance",
  },
  {
    id: GoogleMetric.interaction_rate,
    displayName: "Interactions Rate",
    enabled: true,
    group: "Performance",
    description: "Interactions / Impressions",
  },
  {
    id: GoogleMetric.new_customer_lifetime_value,
    displayName: "New Customer Lifetime Value",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.orders,
    displayName: "Orders",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.video_views_p100,
    displayName: "Video Views at 100%",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_views_p75,
    displayName: "Video Views at 75%",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_views_p50,
    displayName: "Video Views at 50%",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_views_p25,
    displayName: "Video Views at 25%",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_view_rate_p100,
    displayName: "Video Views at 100% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_view_rate_p75,
    displayName: "Video Views at 75% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_view_rate_p50,
    displayName: "Video Views at 50% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_view_rate_p25,
    displayName: "Video Views at 25% (in %)",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.video_view_rate,
    displayName: "Video View Rate",
    enabled: true,
    group: "Media",
    description: "Video Views / Impressions",
  },
  {
    id: GoogleMetric.video_views,
    displayName: "Video Views",
    enabled: true,
    group: "Media",
  },
  {
    id: GoogleMetric.view_through_conversions,
    displayName: "View Through Conversions",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.value_per_all_conversions,
    displayName: "Value per All Conversions",
    enabled: true,
    group: "Conversions",
    description: "All Conversions Value / All Conversions",
  },
  {
    id: GoogleMetric.value_per_conversion,
    displayName: "Value per Conversion",
    enabled: true,
    group: "Conversions",
    description: "Conversions Value / Conversions",
  },
  {
    id: GoogleMetric.cost_per_all_conversions,
    displayName: "Cost per All Conversions",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.cost_per_conversion,
    displayName: "Cost per Conversion",
    enabled: true,
    group: "Conversions",
  },
  {
    id: GoogleMetric.conversions_per_clicks,
    displayName: "Conversions / Clicks",
    enabled: true,
    group: "Conversions",
  },
];
