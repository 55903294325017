import { createAvatar } from "~/shared/utils";
import {
  AppUser,
  AppUserResponse,
  AppUserRole,
  userRoleToTooltip,
  statusToDisplayStatus,
  userRoleToDisplayRole,
  DISABLED_ROLES,
  AppUserRoleToLevel,
} from "~/types/AppUser";
import { PaymentPlanIF } from "~/types/Payment";

export const useAppUser = () => {
  const isAuthenticated = () => {
    const { status } = useAuth();
    return status.value === "authenticated";
  };

  const isAuthorized = (
    appUserMe: AppUser | null,
    requiredRole: AppUserRole,
  ) => {
    if (
      appUserMe &&
      AppUserRoleToLevel[appUserMe.role] >= AppUserRoleToLevel[requiredRole]
    ) {
      return true;
    }
    return false;
  };

  const getMappedAppUsers = (
    users: Array<AppUserResponse>,
    loggedInUser: AppUser | undefined | null,
  ): Array<AppUser> => {
    const mappedUsers = users
      .filter((user) => {
        // For all customer workspaces show only not internal users
        // Remember: Return true means keep it
        return loggedInUser == null || loggedInUser.internal || !user.internal;
      })
      .map((user) => {
        const displayName = getUserDisplayName(
          user.firstname,
          user.lastname,
          user.email,
        );

        const displayImage =
          typeof user.profileImage === "string" && user.profileImage.length > 0
            ? user.profileImage
            : createAvatar(displayName, "initials");

        return {
          ...user,
          displayName,
          profileImage: displayImage,
          displayStatus: statusToDisplayStatus[user.status],
          displayRole: userRoleToDisplayRole[user.role],
        };
      });

    mappedUsers.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedUsers;
  };

  const getUserDisplayName = (
    firstname: string | undefined | null,
    lastname: string | undefined | null,
    email: string,
  ) => {
    if (firstname && lastname) {
      return firstname + " " + lastname;
    } else if (firstname) {
      return firstname;
    } else if (lastname) {
      return lastname;
    } else {
      return email;
    }
  };

  const isAdmin = (appUserMe: AppUser | null) => {
    if (appUserMe) {
      return appUserMe.role === AppUserRole.ADMIN;
    }
    return false;
  };

  const isMember = (appUserMe: AppUser | null) => {
    if (appUserMe) {
      return appUserMe.role === AppUserRole.VIEWEDIT;
    }
    return false;
  };

  const getRoles = () => {
    return Object.values(AppUserRole)
      .filter((role) => !DISABLED_ROLES.includes(role))
      .map((userRole) => {
        return {
          displayValue: userRoleToDisplayRole[userRole],
          description: userRoleToTooltip[userRole],
          value: userRole,
        };
      });
  };

  const canCreate = (dto: {
    existingUsers: AppUser[];
    numToCreate: number;
    paymentPlanInfo: PaymentPlanIF;
  }) => {
    const { numToCreate, paymentPlanInfo, existingUsers } = dto;
    const maxMembers = paymentPlanInfo.restrictions.members;
    const totalUsersAfterAdd = numToCreate + existingUsers.length;
    return totalUsersAfterAdd <= maxMembers;
  };

  const createAppUsers = async (dto: {
    existingUsers: AppUser[];
    toCreate: {
      email: string;
      role: AppUserRole;
      clientIds: number[];
    }[];
    updateLoading: (loading: boolean) => void;
  }) => {
    dto.updateLoading(true);

    const { notify } = useNotifications();
    const appUserStore = useAppUserStore();

    const usersOrError = await appUserStore.createAppUsers(dto.toCreate);

    if (typeof usersOrError === "string") {
      notify(usersOrError, "");
      dto.updateLoading(false);
      return;
    }

    const assignClientsDto = dto.toCreate
      .map((user) => {
        const userId = usersOrError.find((u) => u.email === user.email)?.id;
        return {
          userId,
          clientIds: user.clientIds,
        };
      })
      .filter((user) => typeof user.userId === "number")
      .filter((user) => user.clientIds.length > 0)
      .flatMap((user) => {
        return user.clientIds.map((clientId) => {
          return {
            userId: user.userId as number,
            clientId,
          };
        });
      });

    const errorMaybe =
      await appUserStore.assignClientsToAppUser(assignClientsDto);

    if (errorMaybe) {
      notify(errorMaybe, "");
      dto.updateLoading(false);
      return;
    }

    notify(null, "Users created successfully.");
    dto.updateLoading(false);
  };

  return {
    isAuthenticated,
    isAuthorized,
    getMappedAppUsers,
    getUserDisplayName,
    isAdmin,
    isMember,
    getRoles,
    canCreate,
    createAppUsers,
  };
};
