import { Timeframe } from "./shared";

export type TopCreativeDigestResponse = {
  email: TopCreativeDigestEmailResponse;
  slack: TopCreativeDigestSlackResponse;
};

export type TopCreativeDigestEmailResponse = {
  clientId: number;
  cronSchedule: string;
  numCreatives: number;
  additionalEmails: string[];
  isUserOptedOut: boolean;
  senderName: string;
  senderEmail: string;
  lookback: Timeframe | null;
};

export type TopCreativeDigestSlackResponse = {
  clientId: number;
  cronSchedule: string;
  numCreatives: number;
  slackChannel: string | null;
  enabled: boolean;
  lookback: Timeframe | null;
};

export type TopCreativeDigest = {
  email: TopCreativeDigestEmail;
  slack: TopCreativeDigestSlack;
};

export type TopCreativeDigestEmail = TopCreativeDigestEmailResponse;

export type TopCreativeDigestSlack = TopCreativeDigestSlackResponse;

export type Frequency = "weekly" | "monthly";
export type Weekday =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export const FREQUENCIES: Array<{ value: Frequency; displayValue: string }> = [
  { value: "weekly", displayValue: "Weekly" },
  { value: "monthly", displayValue: "Monthly" },
];

// Show only if the frequency is weekly
export const WEEKDAYS: Array<{ value: Weekday; displayValue: string }> = [
  { value: "monday", displayValue: "Monday" },
  { value: "tuesday", displayValue: "Tuesday" },
  { value: "wednesday", displayValue: "Wednesday" },
  { value: "thursday", displayValue: "Thursday" },
  { value: "friday", displayValue: "Friday" },
  { value: "saturday", displayValue: "Saturday" },
  { value: "sunday", displayValue: "Sunday" },
];

export const SCHEDULE_HOUR = 8;
export const SCHEDULE_MINUTE = 0;
