import { defineStore } from "pinia";
import { WorkspaceOnboardingTaskId } from "~/types/Onboarding";
import { Workspace, WorkspaceResponse } from "~/types/Workspace";

export const useWorkspaceStore = defineStore({
  id: "workspace-store",
  state: () => {
    return {
      workspaces: [],
    } as {
      workspaces: Array<Workspace>;
    };
  },
  actions: {
    async listWorkspaces() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { workspaces: Array<WorkspaceResponse> };
      }>("workspace");
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedWorkspaces } = useWorkspace();
        this.workspaces = getMappedWorkspaces(data.value.data.workspaces);
      }
      return this.workspaces;
    },

    async isValidDomain(domain: string) {
      const { error } = await useDatAdsApiFetch(
        `workspace/domain/${domain}/validate`,
        {
          method: "POST",
        },
      );
      if (error.value) {
        return false;
      }
      return true;
    },

    async updateWorkspace(input: {
      workspaceId: number;
      displayName?: string;
      logo?: File | null;
      bookedOnboarding?: boolean;
      acceptedAgb?: boolean;
      domain?: string;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          workspace: WorkspaceResponse;
        };
      }>(`workspace/${input.workspaceId}`, {
        method: "PATCH",
        body: {
          displayName: input.displayName,
          bookedOnboarding: input.bookedOnboarding,
          acceptedAgb: input.acceptedAgb,
          domain: input.domain,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        let logoUrl: string | null = null;
        if (input.logo) {
          const { errorMessage, url } = await this.uploadLogo({
            logo: input.logo,
            workspaceId: input.workspaceId,
          });
          if (errorMessage) {
            return errorMessage;
          }
          logoUrl = url;
        }
        const { getMappedWorkspaces } = useWorkspace();
        const workspace = getMappedWorkspaces([
          {
            ...data.value.data.workspace,
            logo:
              typeof logoUrl === "string"
                ? logoUrl
                : data.value.data.workspace.logo,
          },
        ])[0];
        this.workspaces = this.workspaces.map((w) => {
          if (w.id === input.workspaceId) {
            return workspace;
          }
          return w;
        });
      }
      return null;
    },

    async uploadLogo(input: { workspaceId: number; logo: File }) {
      const formData = new FormData();
      formData.append("file", input.logo, input.logo.name);

      const { data, error } = await useDatAdsApiFetch<{
        data: { url: string };
      }>(`workspace/${input.workspaceId}/logo`, {
        method: "POST",
        body: formData,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return { errorMessage, url: null };
      }
      if (data.value) {
        return {
          url: data.value.data.url,
          errorMessage: null,
        };
      }
      return { errorMessage: null, url: null };
    },

    async deleteWorkspace(workspaceId: number) {
      const { error } = await useDatAdsApiFetch(`workspace/${workspaceId}`, {
        method: "DELETE",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.workspaces = this.workspaces.filter((w) => w.id !== workspaceId);
      return null;
    },

    async setOnboardingDetails(
      workspaceId: number,
      dto: {
        companyName: string;
        companyType: string;
        domain: string;
      },
    ) {
      const { error } = await useDatAdsApiFetch("app_user/onboarding-details", {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.workspaces = this.workspaces.map((w) => {
        if (w.id === workspaceId) {
          return {
            ...w,
            displayName: dto.companyName,
            domain: dto.domain,
            completedOnboarding: true,
          };
        }
        return w;
      });
      return null;
    },

    async completeTasks(
      workspaceId: number,
      taskIds: WorkspaceOnboardingTaskId[],
    ) {
      const { error } = await useDatAdsApiFetch(
        `workspace/${workspaceId}/tasks/complete`,
        {
          method: "POST",
          body: taskIds.map((taskId) => ({ taskId })),
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.workspaces = this.workspaces.map((workspace) => {
        if (workspace.id === workspaceId) {
          return {
            ...workspace,
            completedTasks: Array.from(
              new Set([...workspace.completedTasks, ...taskIds]),
            ),
          };
        }
        return workspace;
      });
      return null;
    },

    async uncompleteTasks(
      workspaceId: number,
      taskIds: WorkspaceOnboardingTaskId[],
    ) {
      const { error } = await useDatAdsApiFetch(
        `workspace/${workspaceId}/tasks/uncomplete`,
        {
          method: "POST",
          body: taskIds.map((taskId) => ({ taskId })),
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      this.workspaces = this.workspaces.map((workspace) => {
        if (workspace.id === workspaceId) {
          return {
            ...workspace,
            completedTasks: workspace.completedTasks.filter(
              (taskId) => !taskIds.includes(taskId),
            ),
          };
        }
        return workspace;
      });
      return null;
    },
  },
  getters: {
    getWorkspaceById: (state) => (id: number | null | undefined) => {
      const workspace = state.workspaces.find(
        (workspace) => workspace.id === id,
      );
      return workspace ? { ...workspace } : null;
    },
  },
});
