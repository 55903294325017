import { Intercom, trackEvent } from "@intercom/messenger-js-sdk";
import { AppUser } from "~/types/AppUser";
import { Workspace } from "~/types/Workspace";

export const useIntercom = () => {
  const init = (dto: {
    intercomAppId: string;
    appUserMe: AppUser;
    workspace: Workspace;
  }) => {
    const { intercomAppId, appUserMe, workspace } = dto;
    Intercom({
      app_id: intercomAppId,
      name: appUserMe.displayName,
      email: appUserMe.email,
      created_at: Math.floor(new Date(appUserMe.createdAt).getTime() / 1000),
      user_hash: appUserMe.intercomUserHash,
      company: {
        company_id: workspace.id.toString(),
        created_at: Math.floor(new Date(workspace.createdAt).getTime() / 1000),
        name: workspace.displayName,
        plan: workspace.plan,
        datads_cp_cur_month_usage: workspace.curMonthUsage,
        datads_cp_added_payment_details: workspace.addedPaymentDetails,
        datads_cp_canceled_subscription: workspace.canceledSubscription,
        datads_cp_booked_onboarding: workspace.bookedOnboarding,
        datads_cp_subscription_active:
          new Date(workspace.subscriptionEndDate) > new Date(),
        datads_cp_subscription_end_date: Math.floor(
          new Date(workspace.subscriptionEndDate).getTime() / 1000,
        ),
      },
      // Custom attributes
      datads_role: appUserMe.role,
      datads_internal: appUserMe.internal,
      datads_cur_month_usage: workspace.curMonthUsage,
      datads_added_payment_details: workspace.addedPaymentDetails,
      datads_canceled_subscription: workspace.canceledSubscription,
      datads_booked_onboarding: workspace.bookedOnboarding,
      datads_subscription_active:
        new Date(workspace.subscriptionEndDate) > new Date(),
      datads_subscription_end_date: Math.floor(
        new Date(workspace.subscriptionEndDate).getTime() / 1000,
      ),
    });
  };

  const trackIntercomEvent = (eventName: string, metadata = {}) => {
    trackEvent(eventName, metadata);
  };

  return {
    init,
    trackIntercomEvent,
  };
};
