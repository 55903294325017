import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ucMK8VhJjR from "/usr/src/app/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/usr/src/app/.nuxt/floating-vue.mjs";
import plugin_AOuQ1DYzjk from "/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import _01_errorHandling_client_LpIrAPARb2 from "/usr/src/app/plugins/01.errorHandling.client.ts";
import _02_segment_client_YLEJWZAiJu from "/usr/src/app/plugins/02.segment.client.ts";
import _03_flagsmith_client_3udBInEFL1 from "/usr/src/app/plugins/03.flagsmith.client.ts";
import _04_init_client_7HZK93sM3b from "/usr/src/app/plugins/04.init.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ucMK8VhJjR,
  floating_vue_EIcJ7xiw0h,
  plugin_AOuQ1DYzjk,
  _01_errorHandling_client_LpIrAPARb2,
  _02_segment_client_YLEJWZAiJu,
  _03_flagsmith_client_3udBInEFL1,
  _04_init_client_7HZK93sM3b
]