import { TIMEFRAMES } from "~/shared/config";
import {
  FREQUENCIES,
  Frequency,
  SCHEDULE_HOUR,
  SCHEDULE_MINUTE,
  Weekday,
  WEEKDAYS,
} from "~/types/Notification";
import { Timeframe } from "~/types/shared";

export const useNotifications = () => {
  const excludedTimeframes = [
    Timeframe.all_time,
    Timeframe.today,
    Timeframe.yesterday,
    Timeframe.previous_period,
    Timeframe.this_one_month,
    Timeframe.this_one_week,
    Timeframe.twelve_months,
    Timeframe.three_months,
    Timeframe.one_month,
    Timeframe.one_week,
    Timeframe.six_months,
  ];

  const notify = (errorMaybe: string | null, successMessage: string | null) => {
    const notificationStore = useNotificationStore();

    if (errorMaybe) {
      notificationStore.notify({
        type: "error",
        message: errorMaybe,
      });
    } else if (successMessage) {
      notificationStore.notify({
        type: "success",
        message: successMessage,
      });
    }
  };

  const generateCronSchedule = (
    frequency: Frequency,
    weekday: Weekday | null,
  ): string => {
    // Mapping of weekdays to their cron numbers (0 = Sunday)
    const weekdayMap: Record<Weekday, number> = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    switch (frequency) {
      case "weekly":
        // Run at 8am on the specified day of the week
        return `${SCHEDULE_MINUTE} ${SCHEDULE_HOUR} * * ${weekdayMap[weekday!]}`;

      case "monthly":
        // Run at 8am on the first day of the month
        return `${SCHEDULE_MINUTE} ${SCHEDULE_HOUR} 1 * *`;

      default:
        return `${SCHEDULE_MINUTE} ${SCHEDULE_HOUR} * * ${weekdayMap.monday}`;
    }
  };

  const parseCronSchedule = (
    cronSchedule: string,
  ): {
    frequency: Frequency;
    weekday: Weekday | null;
  } => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_minute, _hour, dayOfMonth, _month, dayOfWeek] =
      cronSchedule.split(" ");

    // Mapping of cron numbers to weekdays (0 = Sunday)
    const weekdayMapReverse: Record<number, Weekday> = {
      0: "sunday",
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    };

    // Check for monthly schedule (runs on the first day of every month)
    if (dayOfMonth === "1" && dayOfWeek === "*") {
      return { frequency: "monthly", weekday: null };
    }

    // Check for weekly schedule (runs on a specific day of the week)
    if (dayOfMonth === "*" && dayOfWeek !== "*") {
      const weekdayNumber = parseInt(dayOfWeek);
      if (weekdayNumber >= 0 && weekdayNumber <= 6) {
        return {
          frequency: "weekly",
          weekday: weekdayMapReverse[weekdayNumber],
        };
      }
    }

    return { frequency: "weekly", weekday: "monday" };
  };

  const getScheduleName = (
    frequency: Frequency,
    weekday: Weekday | null,
  ): string => {
    // Find the frequency label
    const frequencyLabel = FREQUENCIES.find(
      (f) => f.value === frequency,
    )?.displayValue;
    if (!frequencyLabel) {
      return "";
    }

    // For weekly frequency, include the weekday
    if (frequency === "weekly") {
      if (!weekday) {
        return "";
      }
      const weekdayLabel = WEEKDAYS.find(
        (w) => w.value === weekday,
      )?.displayValue;
      if (!weekdayLabel) {
        return "";
      }
      return `${frequencyLabel} on ${weekdayLabel}`;
    }

    // For monthly, add "on the 1st"
    if (frequency === "monthly") {
      return `${frequencyLabel} on the 1st`;
    }

    // For daily, just return the frequency
    return frequencyLabel;
  };

  const getLookbackString = (lookback: Timeframe | null) => {
    if (lookback == null) {
      return "Your digest will include the creatives from the previous period";
    }
    const config = TIMEFRAMES.find((tf) => tf.id === lookback);
    if (!config) {
      return "Your digest will include the creatives from the previous period";
    }
    return `Your digest will include the creatives from the ${config.displayName}`;
  };

  return {
    notify,
    generateCronSchedule,
    parseCronSchedule,
    getScheduleName,
    excludedTimeframes,
    getLookbackString,
  };
};
