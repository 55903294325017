export type EmotionClassification = {
  joy: number;
  sadness: number;
  anger: number;
  fear: number;
  surprise: number;
  disgust: number;
};

export enum Emotion {
  joy = "joy",
  sadness = "sadness",
  anger = "anger",
  fear = "fear",
  surprise = "surprise",
  disgust = "disgust",
}

export type BaseEmotionResponse = {
  adId: number;
  emotionClassification: EmotionClassification | null;
  emotionExplanation: string | null;
};

export type CopyEmotionResponse = BaseEmotionResponse;

export type VideoEmotionResponse = BaseEmotionResponse & {
  videoId: string;
};

export type ImageEmotionResponse = BaseEmotionResponse & {
  imageId: string;
};

export interface GetCopyEmotionDto {
  adId: number;
}

export interface GetVideoEmotionDto {
  adId: number;
  videoId: string;
}

export interface GetImageEmotionDto {
  adId: number;
  imageId: string;
}
